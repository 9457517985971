<template>
  <div class="control mt-6 is-flex is-flex-direction-column">
    <a class="button is-success is-medium" :href="oauthUrl" data-method='post'>Attach Spotify</a>
  </div>
</template>

<script>
export default {
  props: {
    oauthUrl: String
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
