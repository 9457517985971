<template>
  <div class="field avatar-uploader">
    <label class="label">{{label}}</label>
    <div class="file is-medium is-boxed has-name">
      <label class="file-label file-preview" :style="{ 'background-image': 'url(' + (imageData || currentAvatarUrl) + ')' }">
        <input class="file-input" type="file"  @change="onAvatarUploaded" :name="inputName" :id="id">
        <span class="file-cta" :class="{loaded: currentAvatarUrl || imageData}">
          <span class="file-icon is-size-3 mr-0">
            <i class="mdi mdi-plus"></i>
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String },
    inputName: { type: String },
    label: { type: String },
    currentAvatarUrl: { type: String },
  },
  data() {
    return {
      imageData: null
    }
  },
  methods: {
    onAvatarUploaded(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    }
  },
}
</script>

<style scoped lang="scss">
.file {
  .file-preview {
    border-radius: 8px!important;
    overflow: hidden;
  }

  .file-cta {
    background: initial!important;
    border-radius: 8px!important;
    width: 130px;
    height: 130px;
    justify-content: center;
    align-items: center;
  
    &.loaded {
      border: none;
      .file-icon {
        display: none;
      }
    }
  }
}

.file-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>