<template>
<img :if="url" :src="url" alt="crypto address qr code">
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: {
    inputString: String,
  },
  data() {
    return {
      url: null,
    }
  },
  created() {
    QRCode.toDataURL(this.inputString, {
      errorCorrectionLevel: 'H',
      margin: 0,
      width: 200,
      color: {
        light: "#FFFFFF00"
      }
    })
      .then(url => {
        this.url = url
      })
      .catch(err => {
        console.error(err)
      })
  }
}
</script>
