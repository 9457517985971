<template>
  <form :action="oauthUrl" method="POST">
    <input type="hidden" name="authenticity_token" :value="token">

    <div class="field">
      <label class="label">Username</label>
      <div class="control">
        <input required="required" type="text" v-model="username" class="input sm-input" title="Your real username" pattern="[a-zA-Z1-9\-_]{4,20}" placeholder="e.g. spacex" @change="usernameChange"/>
      </div>
    </div>

    <div class="control mt-6 is-flex is-flex-direction-column">
      <button class="button is-success is-medium">Attach Linkedin</button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    oauthUrl: String,
    token: String
  },
  created() {
  },
  data() {
    return {
      username: '',
    }
  },
  methods: {
    usernameChange() {
      console.log(this.username);
      Vue.$cookies.set('linkedin_username', this.username);
    }
  }
}
</script>
