<template>
</template>

<script>
export default {
  props: {
  },
  created() {
  },
  data() {
    return {
      selectedSocial: null
    }
  },
  methods: {
    save() {
      console.log('save');
    }
  }
}
</script>
