<template>
  <div class="sm-card post-creator">
    <div class="post-creator-initializer">
      <button class="button is-success is-medium post-mobile-button" @click="showPostConstructor()">
        Create post
      </button>
    </div>

    <div class="post-creator-constructor" style="display: none;">
      <h4 class="title is-4 mb-5">Create post</h4>

      <div class="inputs-block">
        <div class="field">
          <div class="control has-icons-left has-icons-right is-flex-grow-1">
            <textarea class="input is-medium" placeholder="Message" v-model="comment" rows="5"></textarea>
            <span class="icon is-small is-left">
              <i class="mdi mdi-message"></i>
            </span>
          </div>
        </div>

        <div class="field">
          <div class="control has-icons-left has-icons-right is-flex-grow-1">
            <input class="input is-medium" :class="{ 'is-danger': ogError }" placeholder="URL to your post (optional)"
              v-model="socialUrl" @input="debounceInput">
            <span class="icon is-small is-left">
              <i class="mdi"
                :class="{ 'mdi-close': ogError, 'mdi-link': (!loading && !ogError), 'mdi-loading': loading }"></i>
            </span>
          </div>
        </div>
        <p class="help is-danger" v-show="ogError">{{ ogError || "Wrong link" }}</p>

        <div class="field">
          <div class="control has-icons-left">
            <div class="select is-medium" style="width: 100%">
              <select v-model="tierId" style="width: 100%">
                <option value="free">Free tier</option>
                <option :value="tier.value" :key="tier.value" v-for="tier in tiers">{{ tier.title }}</option>
              </select>
            </div>
            <span class="icon is-small is-left">
              <i class="mdi mdi-human-queue"></i>
            </span>
          </div>
        </div>
        <p class="help is-danger" v-show="ogError">{{ ogError || "Wrong link" }}</p>
      </div>

      <div class="field files-field">
        <div class="file-card file-card-uploader">
          <b-upload v-model="files" multiple expanded drag-drop :max-size="maxFileSize">
            +
          </b-upload>
        </div>

        <div class="file-card" v-for="(file, index) in files" :key="index">
          <button class="delete is-small" @click="removeFile(index)"></button>
          <div class="image-preview" :style="{ backgroundImage: `url(${getFileUrl(file)})` }"></div>
          <div class="filename-block">{{ file.name }}</div>
        </div>
      </div>

      <div class="control mt-5">
        <div class="row">
          <button class="button is-success is-medium post-button" :disabled="!isFormValid || ogError" @click="save()">
            Post
          </button>
          <button class="button is-medium post-button" @click="hidePostConstructor()">
            Cancel
          </button>
        </div>
        <div class="help is-danger mt-3" v-show="postError">{{ postError }}</div>
      </div>

      <div class="post-item" v-if="og.title">
        <div class="post-content">
          <div class="post-content-text">
            <div class="post-title">{{og.title}}</div>
            <div class="post-text">{{og.description}}</div>
          </div>
          <div class="post-media">
            <div class="post-media-image" :style="{backgroundImage: `url(${og.image})`}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import axios from 'axios';


export default {
  props: {
    initUrl: String,
    createUrl: String,
    tiers: Array,
  },

  data() {
    return {
      socialUrl: null,
      comment: null,
      og: {},
      ogError: null,
      postError: null,
      loading: false,
      files: [],
      maxFileSize: 5 * 1024 * 1024,
      tierId: 'free',
    }
  },

  computed: {
    isFormValid() {
      return this.og.title || this.comment
    }
  },

  methods: {
    showPostConstructor: function() {
      this.$el.querySelector('.post-creator-initializer').style.display = 'none'
      this.$el.querySelector('.post-creator-constructor').style.display = 'block'
    },

    hidePostConstructor: function() {
      this.$el.querySelector('.post-creator-initializer').style.display = 'block'
      this.$el.querySelector('.post-creator-constructor').style.display = 'none'
    },

    debounceInput: debounce(async function() {
      this.og = {}
      this.ogError = null
      let response = null

      if (!this.socialUrl) return

      this.loading = true
      try {
        response = await axios.get(this.initUrl + `?[post]social_url=${this.socialUrl}`)
      } catch (error) {
        if (error.response && error.response.data.error) {
          this.ogError = error.response.data.error
        } else {
          this.ogError = error
        }

        return;
      } finally {
        this.loading = false
      }

      this.og = response.data.og
    }, 500),

    async save() {
      this.postError = null;

      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        formData.append('post[attachments][]', this.files[i]);
      }

      if (this.comment) formData.append('post[comment]', this.comment);
      if (this.socialUrl) formData.append('post[social_url]', this.socialUrl);
      if (this.tierId) formData.append('post[tier_id]', this.tierId);

      try {
        await axios.post(this.createUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        window.location.reload();
      } catch (error) {
        if (error.response && error.response.data.error) {
          this.postError = error.response.data.error
        } else {
          this.postError = "Unhandled error"
        }
      }
    },

    getFileUrl(file) {
      return URL.createObjectURL(file);
    },

    removeFile(index) {
      this.files.splice(index, 1);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/mixins';

.inputs-block {
  max-width: 600px;
}

@keyframes rotating {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

.mdi-loading {
  animation: rotating .6s linear infinite;
}

.mdi-close {
  color: red
}

.help {
  font-size: 14px;
}

.post-item {
  margin-top: 24px;
}

.comment-input-wrap {
  margin-top: 14px;
}

.files-field {
  @include row;
  flex-wrap: wrap;
  margin-top: 14px;

  .file-card {
    @include row(center, stretch);
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    width: 190px;
    height: 120px;
    position: relative;
    overflow: hidden;

    &.file-card-uploader {
      border: 1px solid #dbdbdb;
      border-style: dashed;
    }

    .field {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 14px;
      margin-bottom: 14px;
    }

    .delete {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;
    }

    .image-preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .filename-block {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss">
@import '../styles/mixins';

.upload.control {
  cursor: pointer;
  height: 100%;
  @include row(center, stretch);

  .upload-draggable {
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    @include row(center, center);
    font-size: 36px;
    color: #909090;

    &:hover {
      background-color: #fafafa!important;
    }
  }
}
</style>
