<template>
  <form action="/socials" method="POST">
    <div class="field">
      <label class="label">Username</label>
      <input required="required" type="hidden" name="social[provider]" value="pinterest">
      <div class="control">
        <input required="required" type="text" name="social[handle]" class="input sm-input" placeholder="my_username">
      </div>
    </div>
    <div class="control mt-6 is-flex is-flex-direction-column">
      <button class="button is-success is-medium">Attach Pinterest</button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
