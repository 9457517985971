export default class Searcher {
  static async run(provider, search) {
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        url: '/searcher/proxy',
        dataType: 'json',
        method: 'POST',
        data: {
          provider: provider,
          search: search
        },  
        success: function(data){
          resolve(data)
        },
        error: function(xhr, status, error){
          reject(error)
        }
      });
    })

    return promise
  }
}