<template>
  <div class="modal sm-modal" :class="{'is-active': active}">
    <div class="modal-background" @click="close()"></div>

    <div class="modal-card" :style="{width: width + 'px'}">
      <header class="modal-card-head">
        <div><slot name="header"></slot></div>
        <button class="delete" aria-label="close" @click="close()"></button>
      </header>

      <section class="modal-card-body">
        <slot name="body"></slot>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: Number,
      default: 414
    }
  },
  data() {
    return {
      active: false
    }
  },
  created() {
    this.$root.$on(`modals:open:${this.name}`, () => {
      this.active = true;
    })

    this.$root.$on(`modals:close:${this.name}`, () => {
      this.active = false
    })
  },
  methods: {
    close() {
      console.log(this.name);
      this.$root.$emit(`modals:close:${this.name}`)
    }
  }
}
</script>
