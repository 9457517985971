<template>
  <a class="sm-card social-card" :class="{'success-result': state == 'done', dev: state == 'dev'}" :href="url" target="_blank" >
    <div class="sm-card-header level is-mobile is-align-items-flex-center">
      <div class="level-left is-align-items-flex-start">
        <!-- <div class="social-avatar-wrap">
          <img src="/packs/media/images/default-avatar-ab39122f6ca54b65d5db42837238e14d.png" class="is-rounded">
        </div> -->
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div class="provider-name">{{providerName}}</div>
        </div>
      </div>
      <div class="level-right">
        <div class="social-icon-wrap">
          <img :src="providerIcon">
        </div>
      </div>
    </div>

    <div class="sm-card-content">
      <div v-if="state == 'waiting'" class="state waiting-state">
        <div class="iconable">
          <i class="mdi mdi-emoticon-happy-outline"></i>
          <span>Ready to search</span>
        </div>
      </div>

      <div v-if="state == 'loading'" class="state loading-state">
        <sm-loader></sm-loader>
      </div>

      <div v-if="state == 'done'" class="state done-state">
        <div class="doneble">
          <div class="avatar" :style="{ backgroundImage: 'url(' + avatarUrl + ')' }" v-show="avatarUrl"></div>
          <div>
            <div class="username">{{title}}</div>
            <div class="description">{{description}}</div>
          </div>
        </div>
      </div>
      
      <div v-if="state == 'nope'" class="state nope-state">
        <div class="iconable">
          <i class="mdi mdi-checkbox-blank-circle-outline"></i>
          <span>User not found</span>
        </div>
      </div>
      
      <div v-if="state == 'error'" class="state error-state">
        <div class="iconable">
          <i class="mdi mdi-alert-circle"></i>
          <span>Search error, please try later</span>
        </div>
      </div>
      
      <div v-if="state == 'dev'" class="state dev-state">
        <div class="iconable">
          <i class="mdi mdi-progress-wrench"></i>
          <span>Development in progress</span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
  import Searcher from '../services/searcher';

  export default {
    props: {
      provider: { type: String },
      providerIcon: { type: String },
      providerName: { type: String },
      isDev: { type: Boolean },
    },
    data() {
      return {
        url: null,
        title: null,
        description: null,
        avatarUrl: null,
        state: 'waiting' // waiting dev loading nope error done
      }
    },
    methods: {
      onAvatarUploaded(event) {}
    },
    created() {
      if (this.isDev) {
        this.state = 'dev'
        return
      }

      this.$root.$on('searcher:search', async (search) => {
        this.state = 'loading'

        try {
          const result = await Searcher.run(this.provider, search)

          if (result.url) {
            this.state = 'done'
            this.url = result.url
            this.title = result.title
            this.avatarUrl = result.avatar_url
            this.description = result.description
          } else {
            this.state = 'nope'
            this.url = null
            this.title = null
            this.avatarUrl = null
            this.description = null
          }
        } catch (e) {
          this.state = 'error'
          console.error(e);
        }
      })
    }
  }
</script>

<style scoped lang="scss">
@import '../styles/mixins';

.sm-card {
  height: 100%;
  display: block;

  &.success-result {
    .social-icon-wrap {
      background-color: #3EB28C;

      img {
        filter: invert(1);
      }
    }

    &[href] {
      &:hover {
        .social-icon-wrap {
          background-color: #5ec4a2;
        }
      }
    }
  }

  &.dev {
    opacity: 0.4;
  }

  &[href] {
    &, .sm-card-header {
      cursor: pointer;
    }
  }

  &:not([href]) {
    cursor: default;

    .social-icon-wrap {
      box-shadow: none;
    }
  }
}

.sm-card-header {
  margin-bottom: 0;
  cursor: default;
  color: inherit;

  .provider-name {
    font-size: 20px;
  }
}

.iconable {
  @include col(center, center);
  margin-top: 12px;

  i.mdi {
    color: #555;
    font-size: 28px;
  }
}

.loading-state {
  @include col(center, center);

  .loader-wrap {
    margin: 16px 0;
  }
}

.doneble {
  @include row();
  margin-top: 12px;

  .avatar {
    $size: 50px;
    min-width: $size;
    width: $size;
    height: $size;
    border-radius: 6px;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .description {
    font-size: 12px;
    color: #555;
    margin-top: 2px;
  }
}

.waiting-state {
  i.mdi {
    color: #666
  }
}

.error-state {
  i.mdi {
    color: #FF0000
  }
}

.nope-state {
  i.mdi {
    color: #ff0000;
  }
}
</style>