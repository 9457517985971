<template>
</template>

<script>
export default {
  props: {
    notice: String,
    type: {
      type: String,
      default: 'is-dark'
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  created() {
    this.$buefy.toast.open({
      message: this.notice,
      type: this.type,
      duration: this.duration
    })
  }
}
</script>
