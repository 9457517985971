<template>
  <form action="/socials" method="POST">
    <hr/>
    <input required="required" type="hidden" name="social[provider]" value="custom">
    <div class="field">
      <label class="label">Card title</label>
      <div class="control">
        <input required="required" type="text" name="social[handle]" class="input sm-input" placeholder="My site" maxlength="16">
      </div>
    </div>
    <div class="field">
      <label class="label">URL</label>
      <div class="control">
        <input required="required" type="text" name="social[link]" class="input sm-input" placeholder="https://www.google.com/">
      </div>
    </div>
    <div class="control mt-6 is-flex is-flex-direction-column">
      <button class="button is-success is-medium">Attach custom link</button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
