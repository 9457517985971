require.context('../images', true)
// require.context('../application/fonts', true)

// Styles
import '@mdi/font/scss/materialdesignicons'
import 'application/styles/public_libs'
import 'application/styles/public'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import Vue from 'vue/dist/vue.esm'
window.Vue = Vue
import VueRegister from 'boot/vueRegister'

import "@lottiefiles/lottie-player";

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Buefy from 'buefy'
Vue.use(Buefy)

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {locale: 'en'})

import jump from 'jump.js'

Rails.start()
Turbolinks.start()

VueRegister(require.context('application', true, /\.*(\.vue|js)$/))

Vue.config.productionTip = false;

Vue.prototype.$jumpToId = function(id){
  jump(`#${id}`, {
    offset: -50,
  })
}

document.addEventListener('turbolinks:load', () => {
  new Vue({
    el: '#vue-root',
    data: {
      heap: {}
    },
    methods: {
      openModal(name, data = null) {
        this.$emit(`modals:open:${name}`, data)
      },

      closeModal(name) {
        this.$emit(`modals:close:${name}`)
      },
    }
  })
})
