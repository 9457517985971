export default {
  bind (button, { arg, value }, vnode) {
    button.copiableListner = button.addEventListener('click', () => {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      button.classList.add('is-loading')
      button.setAttribute('disabled', true)

      setTimeout(() => {
        button.classList.remove('is-loading')
        button.removeAttribute('disabled')

        vnode.context.$buefy.toast.open({
          message: 'Сopied',
          type: 'is-success',
          duration: 3000
        })
      }, 300)
    })
  },

  unbind(button) {
    button.removeEventListener('click', button.copiableListner)
  }
}