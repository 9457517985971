import Vue from 'vue/dist/vue.esm.js';
import camelCase from 'lodash/camelCase'
import startsWith from 'lodash/startsWith'

function requireComponent(fileName, module, type) {
  const componentName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  Vue[type](componentName, (module.default || module))
}

export default function (requiredNamespace) {
  requiredNamespace.keys().forEach(fileName => {
    const module = requiredNamespace(fileName)

    if (startsWith(fileName, './components')) {
      requireComponent(fileName, module, 'component')
    }

    if (startsWith(fileName, './directives')) {
      requireComponent(fileName, module, 'directive')
    }

    if (startsWith(fileName, './filters')) {
      requireComponent(fileName, module, 'filter')
    }
  })
}
