<script>

export default {
  props: {
  },
  data() {
    return {
      search: ''
    }
  },
  created() {
  },
  methods: {
    makeSearch() {
      if (this.search.length < 3) {
        return
      }
      
      this.$root.$emit('searcher:search', this.search)
    }
  }
}
</script>
