<template>
  <div class="donation-card">
    <div class="header is-flex is-align-items-center">
      <div class="icon header-icon">
        <i class="mdi mdi-gift-outline"></i>
      </div>
      <h4>Support</h4>
    </div>

    <form @submit="submit">
      <div class="field is-flex is-align-items-center">
        <div class="control has-icons-left">
          <input class="input is-medium" placeholder="0" type="number" step="1" v-model="amount" :disabled="isDisabled">
          <span class="icon is-small is-left">
            <i class="mdi mdi-currency-usd"></i>
          </span>
        </div>

        <div class="control">
          <b-button :loading="loading" :disabled="isDisabled || amount <= 0" @click="submit" class="button is-primary is-medium">Tip</b-button>
        </div>
      </div>

      <!-- <div class="field">
        <div class="control has-icons-left">
          <input class="input is-medium" placeholder="Your message" type="text" v-model="message">
          <span class="icon is-small is-left">
            <i class="mdi mdi-message-text-outline"></i>
          </span>
        </div>
      </div> -->
    </form>

    <div class="suggest-buttons">
      <b-button
        v-for="(value) in [3, 10, 20, 50]"
        @click="suggest(value)"
        class="button is-primary is-outlined">
          $ {{ value }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    payeeId: Number,
    initUrl: String
  },
  data() {
    return {
      amount: 5,
      loading: false,
      errored: false,
    }
  },
  created() {
  },
  computed: {
    isDisabled() {
      return this.loading || this.errored
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault();

      this.loading = true

      try {
        const result = await axios.post(this.initUrl, {
          payee_id: this.payeeId,
          amount: this.amount,
        })

        console.log(result);

        window.open(result.data.approve_url, '_blank').focus();
      } catch (error) {
        this.$buefy.toast.open({
          message: "There was an error, try again later. We are already working on fixing it",
          type: "is-danger",
          duration: 5000
        })

        console.error(error)

        this.errored = true
      } finally {
        this.loading = false
      }
    },
    suggest(value) {
      this.amount = value;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/_all';
@import '../styles/mixins';
.donation-card {
  border-radius: 8px;
  background: white;
  padding: 14px 20px;
  position: relative;
  overflow: hidden;
}

.header {
  margin-bottom: 20px;

  .header-icon {
    color: #3EB28C;
    margin-right: 12px;

    i.mdi {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;
    display: block;
  }
}

.buttons-field {
  margin-top: 10px;

  .button {
    width: 100%;
  }
}

.field.is-flex {
  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.suggest-buttons {
  display: flex;
  align-items: center;

  @include tablet {
    justify-content: space-between;
  }

  & > button {
    padding: 3px 10px 2px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
