<template>
  <div class="sm-card">
    <div class="sm-tabs">
      <a class="sm-tab" :class="{active: (activeId == wallet.id)}" v-for="wallet in wallets" :key="wallet.id" @click="activeId = wallet.id">{{wallet.type}}</a>
    </div>
    <div class="wallet-contents">
      <div class="wallet-content" v-for="wallet in wallets" :key="wallet.id" v-show="activeId == wallet.id">
        <div class="left">
          <div>
            <div class="title">Tip {{wallet.type}} to this address</div>
            <div class="description">Scan the QR code or copy the address below into your wallet to send some {{wallet.type}}</div>
          </div>
          <div class="address">
            <span class="address-field">{{wallet.address}}</span>
            <button class="button is-success is-medium" v-copiable="wallet.address">Copy</button>
          </div>
        </div>
        <div class="qr-code-wrapper">
          <qr-code :input-string="wallet.qr"></qr-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wallets: Array
  },
  data() {
    return {
      activeId: null,
    }
  },
  created() {
    this.activeId = this.wallets[0].id
  }
}
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/_all';
@import '../styles/mixins';

.wallet-content {
  display: flex;

  @include tablet {
    justify-content: space-between;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .description {
    font-size: 16px;
    color: rgba(139, 139, 139, 0.8);
    max-width: 340px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .address {
    @include tablet {
      @include row(flex-start, center);
    }

   .address-field {
      @include row(flex-start, center);
      border: 1px solid #E1E1E1;
      border-radius: 8px;
      padding: 10px 20px;
      color: #8B8B8B;

      @include tablet {
        min-width: 400px;
        margin-right: 16px;
      }

      @include mobile {
        padding: 8px 12px;
        margin: 12px 0;
      }
    }
  }

  .qr-code-wrapper {
    padding: 26px;
    background: rgba(240, 240, 240, 0.5);
    border-radius: 8px;

    @include mobile {
      margin-top: 16px;
    }

    @include tablet {
      margin-top: -74px;
    }
  }
}
</style>
